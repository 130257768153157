@import url("https://hello.myfonts.net/count/3e7c21");
@import url("https://hello.myfonts.net/count/3e7c28");

@font-face {
  font-family: "IonicNo5-Medium";
  src: url('../fonts/ionic5_medium.woff2') format('woff2'), url('../fonts/ionic5_medium.woff') format('woff');
}

@font-face {
  font-family: "LazareGrotesk-Light";
  src: url('../fonts/lazare_grotesk_light.woff2') format('woff2'), url('../fonts/lazare_grotesk_light.woff') format('woff');
}

//fonts
@mixin fontRunText() {
  font-family: LazareGrotesk-Light, Arial, Helvetica, sans-serif;
  font-weight: normal;
  letter-spacing: 0.06em;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

@mixin fontTitle() {
  font-family: IonicNo5-Medium, serif;
  font-weight: normal;
  letter-spacing: 0;
  color: var(--c-title);
}

// utilities
$breakpoints: (
  'desktop-xl': 'min-width: 1921px',
  'desktop-l': 'max-width: 1440px',
  'desktop': 'max-width: 1280px',
  'tablet': 'max-width: 1024px',
  'tablet-s': 'max-width: 768px',
  'mobile-layout': 'max-width: 1023px',
  'mobile-l': 'max-width: 560px',
  'mobile': 'max-width: 414px',
  'mobile-s': 'max-width: 320px',
  'vertical': 'max-aspect-ratio: 12/8',
  'vertical-xl': 'max-aspect-ratio: 12/10'
);

@mixin viewport($name) {
  @if map-has-key($breakpoints, $name) {
    @media (#{map-get($breakpoints, $name)}) {
      @content;
    }
  }
}

@mixin hover() {
  @media not all and (hover: none) {
    @content;
  }
}

@mixin anti-aliased {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

@mixin aliased {
  -webkit-font-smoothing: auto;
  -moz-font-smoothing: auto;
  -o-font-smoothing: auto;
}

@mixin hyphens {
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}

// helper classes
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.visually-hidden a:focus,
.visually-hidden input:focus,
.visually-hidden button:focus {
  position: static;
  width: auto;
  height: auto;
}

// styles
:root {
  //colors
  --c-text: rgb(0, 0, 0);
  --c-title: rgb(5, 43, 255);
  --c-focus: rgb(5, 43, 255, 0.25);
  --c-bg: rgb(230, 230, 230);
  --c-tag: rgba(0, 0, 0, 0.1);

  //sizes
  --s-text: 2.3rem;
  --s-text-s: calc(var(--s-text) * 0.5);
  --s-lh: 1.65;
  --s-h2: calc(var(--s-text) * 2.4);
  --s-lh-h2: 1.1;
  --s-h3: calc(var(--s-text) * 1.5);
  --s-lh-h3: 1.25;
  --s-gutter: 6vw;
  --s-p-margin-top: 2em;
  --s-p-margin-bottom: 1.6em;
  --s-subline-margin-bottom: 4em;
  --s-line: 2px;
  --s-padding-column: calc(var(--s-gutter) * 2);
  --s-vertical-spacing: calc(var(--s-gutter) * 2);

  //duration
  --d-text-content: 0.25s;

  //eases https://matthewlein.com/tools/ceaser
  --e-in: cubic-bezier(0.895, 0.03, 0.685, 0.22); // quart
  --e-out: cubic-bezier(0.165, 0.84, 0.44, 1); // quart
  --e-inout: cubic-bezier(0.77, 0, 0.175, 1); // quart
  --e-text-content: var(--e-out);

  @include viewport ('tablet') {
    --s-text: 2.7rem;
  }

  @include viewport ('mobile-layout') {
    --s-text: 4rem;
    --s-text-s: calc(var(--s-text) * 0.6);
    --s-padding-column: 0;
    --s-p-margin-bottom: 0.8em;
    --s-subline-margin-bottom: 2em;
    --s-vertical-spacing: calc(var(--s-gutter) * 3.5);
  }

  @include viewport ('mobile-l') {
    --s-text: 5rem;
    --s-h2: calc(var(--s-text) * 1.5);
    --s-h3: calc(var(--s-text) * 1.2);
    --s-subline-margin-bottom: 3.2em;
  }

  @include viewport ('mobile') {
    --s-text: 6rem;
    --s-h2: calc(var(--s-text) * 1.5);
    --s-h3: calc(var(--s-text) * 1.2);
  }

  @include viewport ('mobile-s') {
    --s-text-s: calc(var(--s-text) * 0.75);
  }
}
